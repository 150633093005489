import React from 'react'

import SiteLogo from 'components/base/SiteLogo'
import UserSuppliedText from 'components/base/UserSuppliedText'
import Button, { Theme as ButtonTheme } from 'components/base/Button'

import ModalForgetMe from 'components/blocks/Modal/ForgetMe'
import Tooltip from 'components/blocks/Tooltip'

import {
  MenuWrap,
  Menu,
  MenuInner,
  MainMenu,
  MainMenuContent,
  Burger,
  CloseX,
  MainMenuContentTop,
  ForgetMyInfoWrapper,
  ForgetMyInfoContent,
  TopTitle,
  ToolTipWrapper,
  Link,
} from './styles'

import { Props } from './types'

const MainNav: React.FC<Props> = ({
  applicantFirstName,
  hide,
  isOpen,
  show,
  showForgetMePrompt,
  onForgetMeConfirm,
  onForgetMeOpen,
}): React.ReactElement => (
  <>
    <MenuWrap>
      <Burger onClick={show} data-testid='main-nav-toggle' />
      {isOpen ? (
        <Menu>
          <MenuInner>
            <CloseX onClick={hide} />
            <MainMenu>
              <Link to='/' onClick={hide}>
                <SiteLogo />
              </Link>
              <MainMenuContent>
                <MainMenuContentTop>
                  {(applicantFirstName && <TopTitle>Hi <UserSuppliedText>{applicantFirstName}</UserSuppliedText>,</TopTitle>) || undefined}
                  {(showForgetMePrompt && (
                    <ForgetMyInfoWrapper>
                      <ForgetMyInfoContent>
                        <Button
                          theme={ButtonTheme.link}
                          onClick={onForgetMeOpen}
                        >
                          Forget My Info
                        </Button>
                        <ToolTipWrapper>
                          <Tooltip>
                            Insurance Lounge stores your quote entries on your computer in order to save you time and prevent the need to re-enter the same information.
                            To clear it, click &quot;Forget My Info.&quot;
                          </Tooltip>
                        </ToolTipWrapper>
                      </ForgetMyInfoContent>
                    </ForgetMyInfoWrapper>
                  )) || undefined}
                </MainMenuContentTop>
                <Link to='/quote/new' onClick={hide}>
                  Quote &amp; Buy
                </Link>
                <Link to='/products' onClick={hide}>
                  Products
                </Link>
                <Link to='/locations/storefronts' onClick={hide}>
                  Storefronts
                </Link>
                <Link to='/franchising' onClick={hide}>
                  Franchising
                </Link>
                <Link to='/blog' onClick={hide}>
                  Blog
                </Link>
                <Link to='/about-us' onClick={hide}>
                  About Us
                </Link>
                <Link to='/support' onClick={hide}>
                  Support
                </Link>
                <Link to='/contact' onClick={hide}>
                  Contact Us
                </Link>
              </MainMenuContent>
            </MainMenu>
          </MenuInner>
        </Menu>
      ) : undefined}
    </MenuWrap>

    <ModalForgetMe onConfirm={onForgetMeConfirm} />
  </>
)

export default MainNav
